"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ForumsCreateAttachment = exports.ForumsCreateAttachmentOperationId = void 0;
const ClientController_1 = require("../../../../../ClientController");
exports.ForumsCreateAttachmentOperationId = 'ForumsCreateAttachment';
/**
 * @path /forums/:forumId/attachments
 * @generated
 */
exports.ForumsCreateAttachment = (0, ClientController_1.ClientControllerFactory)({
    path: '/forums/:forumId/attachments',
    method: 'POST',
    definedStatusCodes: [200, 400, 401, 404, 500],
});
