"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetCourseForumDetails = exports.GetCourseForumDetailsOperationId = void 0;
const ClientController_1 = require("../../../../../../ClientController");
exports.GetCourseForumDetailsOperationId = 'GetCourseForumDetails';
/**
 * Get forum, course, and occurrence details
 *
 * @path /forums/details/:educatorSlug/:courseSlug
 * @generated
 */
exports.GetCourseForumDetails = (0, ClientController_1.ClientControllerFactory)({
    path: '/forums/details/:educatorSlug/:courseSlug',
    method: 'GET',
    definedStatusCodes: [200, 404, 400, 500],
});
