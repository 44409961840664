"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ForumsPinPost = exports.ForumsPinPostOperationId = void 0;
const ClientController_1 = require("../../../../../../../ClientController");
exports.ForumsPinPostOperationId = 'ForumsPinPost';
/**
 * Pin a forum post (Admin only)
 *
 * @path /forums/:forumId/posts/:postId/pin
 * @generated
 */
exports.ForumsPinPost = (0, ClientController_1.ClientControllerFactory)({
    path: '/forums/:forumId/posts/:postId/pin',
    method: 'POST',
    definedStatusCodes: [204, 400, 401, 404, 500],
});
