"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ForumsGetPost = exports.ForumsGetPostOperationId = void 0;
const ClientController_1 = require("../../../../../../ClientController");
exports.ForumsGetPostOperationId = 'ForumsGetPost';
/**
 * @path /forums/:forumId/posts/:postId
 * @generated
 */
exports.ForumsGetPost = (0, ClientController_1.ClientControllerFactory)({
    path: '/forums/:forumId/posts/:postId',
    method: 'GET',
    definedStatusCodes: [200, 400, 401, 404, 500],
});
