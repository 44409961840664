"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ForumsListPostComments = exports.ForumsListPostCommentsOperationId = void 0;
const ClientController_1 = require("../../../../../../../ClientController");
exports.ForumsListPostCommentsOperationId = 'ForumsListPostComments';
/**
 * @path /forums/:forumId/posts/:postId/comments
 * @generated
 */
exports.ForumsListPostComments = (0, ClientController_1.ClientControllerFactory)({
    path: '/forums/:forumId/posts/:postId/comments',
    method: 'GET',
    definedStatusCodes: [200, 400, 401, 500],
});
