"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UsersPrepareAvatarUpload = exports.UsersPrepareAvatarUploadOperationId = void 0;
const ClientController_1 = require("../../../../ClientController");
exports.UsersPrepareAvatarUploadOperationId = 'UsersPrepareAvatarUpload';
/**
 * Get URL to upload an avatar
 *
 * @path /users/avatar
 * @generated
 */
exports.UsersPrepareAvatarUpload = (0, ClientController_1.ClientControllerFactory)({
    path: '/users/avatar',
    method: 'GET',
    definedStatusCodes: [200, 400, 401, 500],
});
