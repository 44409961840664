"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ForumsCastVoteOnPoll = exports.ForumsCastVoteOnPollOperationId = void 0;
const ClientController_1 = require("../../../../../../../../ClientController");
exports.ForumsCastVoteOnPollOperationId = 'ForumsCastVoteOnPoll';
/**
 * @path /forums/:forumId/polls/:pollId/cast-vote/:optionId
 * @generated
 */
exports.ForumsCastVoteOnPoll = (0, ClientController_1.ClientControllerFactory)({
    path: '/forums/:forumId/polls/:pollId/cast-vote/:optionId',
    method: 'POST',
    definedStatusCodes: [200, 400, 401, 404, 500],
});
