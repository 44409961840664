"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UsersAvatarUpload = exports.UsersAvatarUploadOperationId = void 0;
const ClientController_1 = require("../../../../ClientController");
exports.UsersAvatarUploadOperationId = 'UsersAvatarUpload';
/**
 * Completes a user avatar upload and set's the user's avatar
 *
 * @path /users/avatar
 * @generated
 */
exports.UsersAvatarUpload = (0, ClientController_1.ClientControllerFactory)({
    path: '/users/avatar',
    method: 'POST',
    definedStatusCodes: [204, 400, 401, 500],
});
