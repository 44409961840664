"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ForumsEditPost = exports.ForumsEditPostOperationId = void 0;
const ClientController_1 = require("../../../../../../ClientController");
exports.ForumsEditPostOperationId = 'ForumsEditPost';
/**
 * @path /forums/:forumId/posts/:postId
 * @generated
 */
exports.ForumsEditPost = (0, ClientController_1.ClientControllerFactory)({
    path: '/forums/:forumId/posts/:postId',
    method: 'PATCH',
    definedStatusCodes: [204, 400, 401, 404, 500],
});
