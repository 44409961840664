"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ForumsVideoAttachmentsWebhook = exports.ForumsVideoAttachmentsWebhookOperationId = void 0;
const ClientController_1 = require("../../../../../../ClientController");
exports.ForumsVideoAttachmentsWebhookOperationId = 'ForumsVideoAttachmentsWebhook';
/**
 * @path /forums/attachments/videos/webhook
 * @generated
 */
exports.ForumsVideoAttachmentsWebhook = (0, ClientController_1.ClientControllerFactory)({
    path: '/forums/attachments/videos/webhook',
    method: 'POST',
    definedStatusCodes: [204, 500],
});
