"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ForumsReactToPost = exports.ForumsReactToPostOperationId = void 0;
const ClientController_1 = require("../../../../../../../ClientController");
exports.ForumsReactToPostOperationId = 'ForumsReactToPost';
/**
 * @path /forums/:forumId/posts/:postId/react
 * @generated
 */
exports.ForumsReactToPost = (0, ClientController_1.ClientControllerFactory)({
    path: '/forums/:forumId/posts/:postId/react',
    method: 'POST',
    definedStatusCodes: [204, 400, 401, 404, 500],
});
