"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ForumsListPinnedPosts = exports.ForumsListPinnedPostsOperationId = void 0;
const ClientController_1 = require("../../../../../../ClientController");
exports.ForumsListPinnedPostsOperationId = 'ForumsListPinnedPosts';
/**
 * @path /forums/:forumId/posts/pinned
 * @generated
 */
exports.ForumsListPinnedPosts = (0, ClientController_1.ClientControllerFactory)({
    path: '/forums/:forumId/posts/pinned',
    method: 'GET',
    definedStatusCodes: [200, 400, 401, 500],
});
