"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ForumsDeleteComment = exports.ForumsDeleteCommentOperationId = void 0;
const ClientController_1 = require("../../../../../../../../ClientController");
exports.ForumsDeleteCommentOperationId = 'ForumsDeleteComment';
/**
 * @path /forums/:forumId/posts/:postId/comments/:commentId
 * @generated
 */
exports.ForumsDeleteComment = (0, ClientController_1.ClientControllerFactory)({
    path: '/forums/:forumId/posts/:postId/comments/:commentId',
    method: 'DELETE',
    definedStatusCodes: [204, 400, 401, 404, 500],
});
