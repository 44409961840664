"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReviewsImportExternalReviews = exports.ReviewsImportExternalReviewsOperationId = void 0;
const ClientController_1 = require("../../../../ClientController");
exports.ReviewsImportExternalReviewsOperationId = 'ReviewsImportExternalReviews';
/**
 * @path /reviews/import-external
 * @generated
 */
exports.ReviewsImportExternalReviews = (0, ClientController_1.ClientControllerFactory)({
    path: '/reviews/import-external',
    method: 'POST',
    definedStatusCodes: [200, 400, 401, 403, 500],
});
