"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ForumAttachmentsUploadUrl = exports.ForumAttachmentsUploadUrlOperationId = void 0;
const ClientController_1 = require("../../../../../../ClientController");
exports.ForumAttachmentsUploadUrlOperationId = 'ForumAttachmentsUploadUrl';
/**
 * @path /forums/:forumId/attachments/upload-url
 * @generated
 */
exports.ForumAttachmentsUploadUrl = (0, ClientController_1.ClientControllerFactory)({
    path: '/forums/:forumId/attachments/upload-url',
    method: 'GET',
    definedStatusCodes: [200, 400, 401, 500],
});
