"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UsersUpdateLastProfilePicturePromptAt = exports.UsersUpdateLastProfilePicturePromptAtOperationId = void 0;
const ClientController_1 = require("../../../../ClientController");
exports.UsersUpdateLastProfilePicturePromptAtOperationId = 'UsersUpdateLastProfilePicturePromptAt';
/**
 * Updates the last time the user was prompted to upload their profile picture
 *
 * @path /users/update-last-profile-picture-prompt-at
 * @generated
 */
exports.UsersUpdateLastProfilePicturePromptAt = (0, ClientController_1.ClientControllerFactory)({
    path: '/users/update-last-profile-picture-prompt-at',
    method: 'POST',
    definedStatusCodes: [204, 400, 401, 500],
});
