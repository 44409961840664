"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ForumsUnsubscribeFromPost = exports.ForumsUnsubscribeFromPostOperationId = void 0;
const ClientController_1 = require("../../../../../../../ClientController");
exports.ForumsUnsubscribeFromPostOperationId = 'ForumsUnsubscribeFromPost';
/**
 * @path /forums/:forumId/posts/:postId/unsubscribe
 * @generated
 */
exports.ForumsUnsubscribeFromPost = (0, ClientController_1.ClientControllerFactory)({
    path: '/forums/:forumId/posts/:postId/unsubscribe',
    method: 'POST',
    definedStatusCodes: [204, 400, 401, 404, 500],
});
