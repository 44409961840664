"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ForumsEditAttachment = exports.ForumsEditAttachmentOperationId = void 0;
const ClientController_1 = require("../../../../../../ClientController");
exports.ForumsEditAttachmentOperationId = 'ForumsEditAttachment';
/**
 * @path /forums/:forumId/attachments/:attachmentId
 * @generated
 */
exports.ForumsEditAttachment = (0, ClientController_1.ClientControllerFactory)({
    path: '/forums/:forumId/attachments/:attachmentId',
    method: 'PATCH',
    definedStatusCodes: [204, 400, 401, 404, 500],
});
