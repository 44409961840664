"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ForumsReactToComment = exports.ForumsReactToCommentOperationId = void 0;
const ClientController_1 = require("../../../../../../../../../ClientController");
exports.ForumsReactToCommentOperationId = 'ForumsReactToComment';
/**
 * @path /forums/:forumId/posts/:postId/comments/:commentId/react
 * @generated
 */
exports.ForumsReactToComment = (0, ClientController_1.ClientControllerFactory)({
    path: '/forums/:forumId/posts/:postId/comments/:commentId/react',
    method: 'POST',
    definedStatusCodes: [204, 400, 401, 404, 500],
});
