"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ForumsReplyToComment = exports.ForumsReplyToCommentOperationId = void 0;
const ClientController_1 = require("../../../../../../../../../ClientController");
exports.ForumsReplyToCommentOperationId = 'ForumsReplyToComment';
/**
 * @path /forums/:forumId/posts/:postId/comments/:commentId/replies
 * @generated
 */
exports.ForumsReplyToComment = (0, ClientController_1.ClientControllerFactory)({
    path: '/forums/:forumId/posts/:postId/comments/:commentId/replies',
    method: 'POST',
    definedStatusCodes: [200, 400, 401, 404, 500],
});
