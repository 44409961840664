"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ForumsSendFeaturedPostEmail = exports.ForumsSendFeaturedPostEmailOperationId = void 0;
const ClientController_1 = require("../../../../../../../ClientController");
exports.ForumsSendFeaturedPostEmailOperationId = 'ForumsSendFeaturedPostEmail';
/**
 * Send featured post email (Admin only)
 *
 * @path /forums/:forumId/posts/:postId/send-featured-email
 * @generated
 */
exports.ForumsSendFeaturedPostEmail = (0, ClientController_1.ClientControllerFactory)({
    path: '/forums/:forumId/posts/:postId/send-featured-email',
    method: 'POST',
    definedStatusCodes: [204, 400, 401, 404, 500],
});
