"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ForumsSubscribeToForum = exports.ForumsSubscribeToForumOperationId = void 0;
const ClientController_1 = require("../../../../../ClientController");
exports.ForumsSubscribeToForumOperationId = 'ForumsSubscribeToForum';
/**
 * @path /forums/:forumId/subscribe
 * @generated
 */
exports.ForumsSubscribeToForum = (0, ClientController_1.ClientControllerFactory)({
    path: '/forums/:forumId/subscribe',
    method: 'POST',
    definedStatusCodes: [204, 400, 401, 404, 500],
});
