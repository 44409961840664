"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ForumsEditComment = exports.ForumsEditCommentOperationId = void 0;
const ClientController_1 = require("../../../../../../../../ClientController");
exports.ForumsEditCommentOperationId = 'ForumsEditComment';
/**
 * @path /forums/:forumId/posts/:postId/comments/:commentId
 * @generated
 */
exports.ForumsEditComment = (0, ClientController_1.ClientControllerFactory)({
    path: '/forums/:forumId/posts/:postId/comments/:commentId',
    method: 'PATCH',
    definedStatusCodes: [204, 400, 401, 404, 500],
});
