"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ForumsAddPostComment = exports.ForumsAddPostCommentOperationId = void 0;
const ClientController_1 = require("../../../../../../../ClientController");
exports.ForumsAddPostCommentOperationId = 'ForumsAddPostComment';
/**
 * @path /forums/:forumId/posts/:postId/comments
 * @generated
 */
exports.ForumsAddPostComment = (0, ClientController_1.ClientControllerFactory)({
    path: '/forums/:forumId/posts/:postId/comments',
    method: 'POST',
    definedStatusCodes: [200, 400, 401, 404, 500],
});
