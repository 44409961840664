"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ForumsDeletePost = exports.ForumsDeletePostOperationId = void 0;
const ClientController_1 = require("../../../../../../ClientController");
exports.ForumsDeletePostOperationId = 'ForumsDeletePost';
/**
 * @path /forums/:forumId/posts/:postId
 * @generated
 */
exports.ForumsDeletePost = (0, ClientController_1.ClientControllerFactory)({
    path: '/forums/:forumId/posts/:postId',
    method: 'DELETE',
    definedStatusCodes: [204, 400, 401, 404, 500],
});
