"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ForumsSubscribeToPost = exports.ForumsSubscribeToPostOperationId = void 0;
const ClientController_1 = require("../../../../../../../ClientController");
exports.ForumsSubscribeToPostOperationId = 'ForumsSubscribeToPost';
/**
 * @path /forums/:forumId/posts/:postId/subscribe
 * @generated
 */
exports.ForumsSubscribeToPost = (0, ClientController_1.ClientControllerFactory)({
    path: '/forums/:forumId/posts/:postId/subscribe',
    method: 'POST',
    definedStatusCodes: [204, 400, 401, 404, 500],
});
